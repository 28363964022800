<template>
  <el-table :data="list" stripe style="width: 100%">
    <el-table-column label="Date" prop="date" width="180" />
    <el-table-column label="Name" prop="name" width="180" />
    <el-table-column label="Address" prop="address" />
  </el-table>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'DashBoardData',
    setup() {
      const list = [
        {
          date: '2016-05-03',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
        {
          date: '2016-05-02',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
        {
          date: '2016-05-04',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
        {
          date: '2016-05-01',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
      ]

      return {
        list,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
